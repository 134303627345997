<template>
  <div>
    <div class="row row-sm">
      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Fournisseur <span class="text-danger">*</span> </label>
        <!-- <select
          v-model="filter.supplier_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            {{ supplier.society }}
          </option>
        </select> -->

        <vue-select 
          :options="suppliers"
          v-model="filter.supplier_reference"
          placeholder="Fournisseur"
          labelColumn="society"
          valueColumn="reference"
        />
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-xl-3 col-lg-3 col-md-6 col-12 my-3">
        <label for=""> État de paiement </label>
        <select v-model="filter.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
      
    </div>
    <br /> 
    <div class="row justify-content-end">
      <div class="col-xl-auto col-12 my-xl-0 my-3">
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>

      <div class="col-xl-auto col-12 my-xl-0 my-3">
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>

      <div class="col-xl-auto col-12 my-xl-0 my-3">
        <button
          @click="$router.push({ name: 'purchases-items-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer de nouveaux achats.
        </button>
      </div>
    </div>
    <br />
    <vue-data-table 
      :headers="headers" 
      :data="purchases" 
      :items-per-page="10" 
      :buttons="buttons"
      :summable-columns="summableColumns"
    />
   </div>
</template>
 
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      headers: [
        { field: 'reference', label: 'Référence', class: '' },
        { field: 'supplierSociety', label: 'Fournisseur', class: '' },
        { field: 'date', label: 'Date', class: '' },
        { field: 'totalPriceTTC', label: 'M. Total', class: 'text-primary ' },
        { field: 'totalQuantity', label: 'Total Qté', class: 'text-secondary ' },
        { field: 'amountPaid', label: 'M. Payé', class: 'text-success ' },
        { field: 'amountUnpaid', label: 'M. Impayé', class: 'text-danger ' },
        { field: 'userFullName', label: 'Créer par', class: '' },
      ],
      buttons: [
        {
          label: 'Détails',
          icon: 'bi bi-info-square',
          class: 'btn',
          action: (purchase) => {
            this.$router.push({
              name: 'purchases-details',
              params: { reference: purchase.reference },
            });
          },
        },
      ],
      summableColumns: [
        { field: 'totalPriceTTC', class: 'text-primary' },
        { field: 'totalQuantity', class: 'text-secondary' },
        { field: 'amountPaid', class: 'text-success' },
        { field: 'amountUnpaid', class: 'text-danger' }
      ],
      
      
    
    };
  },
  computed: {
    ...mapGetters("purchase", {
      purchases: "getAll",
      totalQuantity: "getPurchasesTotalQuantity",
      totalPriceTTC: "getPurchasesTotalPriceTTC",
      totalPaid: "getPurchasesTotalPaid",
      totalUnpaid: "getPurchasesTotalUnpaid",
    }),
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  mounted() {
    this.$store.dispatch("purchase/getAll");
    this.$store.dispatch("supplier/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("purchase/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("purchase/getAll");
    },

    async toFilter(data) {
      return await this.$store.dispatch("purchase/filter", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
    date: (value) => {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
